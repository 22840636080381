// CardsCarouselFallback.tsx
import React from 'react';
import classNames from 'classnames';

interface CardsCarouselFallBackProps {
  title?: string;
  subtitle?: string;
  small?: boolean;
}

export const CardsCarouselFallBack: React.FC<CardsCarouselFallBackProps> = ({
  title,
  subtitle,
  small,
}) => {
  return (
    <div className="px-5 mt-4">
      <div className="flex flex-col justify-center grow my-3">
        <h2 className="text-left text-2xl font-bold">{title}</h2>
        <p className="text-left md:text-base text-sm">{subtitle}</p>
      </div>
      <div className="flex items-center justify-left gap-6">
        {Array.from({ length: 6 }).map((_, idx) => (
          <div
            key={idx}
            className={classNames(
              'animate-pulse bg-gray-300 rounded-2xl mb-2 shrink-0',
              {
                'w-80 h-40': small,
                'w-56 h-60': !small,
              }
            )}
          />
        ))}
      </div>
    </div>
  );
};
