// index.tsx
import React, { Suspense } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { CarouselContainer } from '@/components/CarouselContainer';
import { CardsCarouselFallBack } from '@/components/CardsCarouselFallback';
import SearchModal from '@/components/SearchModal';
import Footer from '@/components/Footer';
import BannerImage from '@/public/banner.png';
import { getOrganizationJsonLd } from '@/utils/seo/schema.org';
import Head from 'next/head';
import { GetServerSidePropsContext } from 'next';
import { NextSeo } from 'next-seo';
import HowToUseSection from '@/components/HowToUseSection';
import { getCategories } from '../api/categories';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { setCookie } from 'nookies';
import { CITY_TO_READABLE, SUPPORTED_CITIES } from '@/constants';

type HomeProps = {
  categories: SpaceCategory[];
  city: string;
};

const Home = ({ categories, city }: HomeProps) => {
  const [showSearchModal, setShowSearchModal] = React.useState(false);
  const router = useRouter();
  const language = router.locale as SupportedLanguages;
  const isSpanish = language === 'es';

  const DiscoverCarousel = React.useMemo(
    () =>
      dynamic(() => import('@/components/DiscoverCarousel'), {
        loading: () => (
          <CardsCarouselFallBack
            title={
              isSpanish
                ? `Descubre ${CITY_TO_READABLE[city]}`
                : `Discover ${CITY_TO_READABLE[city]}`
            }
            small
          />
        ),
        ssr: false,
      }),
    [isSpanish, city]
  );

  const handleSearchFocus = () => {
    setShowSearchModal(true);
  };

  const handleCloseSearchModal = () => {
    setShowSearchModal(false);
  };

  const carouselSections = React.useMemo(() => {
    if (city !== 'bogota')
      return [
        {
          category: 'zona-t',
          title: 'Zona T',
          fetchUrl: `/api/spaces?category=zona-t&limit=8&city=${city}`,
        },
      ];

    return [
      {
        category: 'all',
        title: isSpanish ? 'Sitios Nuevos' : 'New Places',
        fetchUrl: `/api/spaces?is_recent=true&city=${city}`,
      },
      {
        category: 'calle-85',
        title: 'Calle 85',
        fetchUrl: `/api/spaces?category=calle-85&limit=8&city=${city}`,
      },
      {
        category: 'zona-chapinero',
        title: 'Chapinero',
        fetchUrl: `/api/spaces?category=zona-chapinero&limit=8&city=${city}`,
      },
      {
        category: 'zona-galerias',
        title: 'Galerías',
        fetchUrl: `/api/spaces?category=zona-galerias&limit=8&city=${city}`,
      },
      {
        category: 'zona-modelia',
        title: 'Modelia',
        fetchUrl: `/api/spaces?category=zona-modelia&limit=8&city=${city}`,
      },
      {
        category: '1ro-de-mayo',
        title: '1ro de Mayo',
        fetchUrl: `/api/spaces?category=1ro-de-mayo&limit=8&city=${city}`,
      },
      {
        category: 'eventos',
        title: 'Eventos',
        fetchUrl: `/api/spaces?category=eventos&limit=8&city=${city}`,
      },
    ];
  }, [city, isSpanish]);

  return (
    <>
      <NextSeo
        title="Tikipal - Descubre las mejores fiestas de la ciudad"
        description="Tu destino definitivo para todas tus fiestas. Descubre una amplia selección de fiestas en los mejores sitios de la ciudad."
        canonical="https://tikipal.com.co/"
        languageAlternates={[
          {
            hrefLang: 'es',
            href: 'https://tikipal.com.co/',
          },
        ]}
        openGraph={{
          url: 'https://tikipal.com.co/',
          title: 'Tikipal - Descubre las mejores fiestas de la ciudad',
          description:
            'Tu destino definitivo para todas tus fiestas. Descubre una amplia selección de fiestas en los mejores sitios de la ciudad.',
          type: 'website',
          images: [
            {
              url: 'https://tikipal.com.co/thumbnail.jpeg',
              width: 200,
              height: 200,
              alt: 'Tikipal',
            },
          ],
          site_name: 'Tikipal',
        }}
      />
      <div>
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={getOrganizationJsonLd()}
            key="product-jsonld"
          />
        </Head>
        <div className="flex flex-col relative overflow-x-hidden">
          <div className="relative flex flex-col justify-center items-center h-[15rem] md:h-[20rem] lg:h-[25rem] border-0 text-center rounded-b-2xl overflow-hidden">
            <Image
              className="absolute z-0 object-cover"
              src={BannerImage}
              alt="banner"
              loading="lazy"
              quality={50}
              sizes="(max-width: 768px) 100vw,
            (max-width: 1200px) 50vw,
            33vw"
              fill
            />
            <div className="w-full h-full bg-[#000419] opacity-20 absolute top-0 left-0" />
            <div className="pt-6 lg:pt-0 z-10 flex flex-col justify-center items-center h-[14rem] md:h-[18rem] lg:h-[20rem]">
              <div className="flex flex-col items-center text-center z-10">
                <h2 className="text-white font-bold text-2xl md:text-3xl px-4">
                  {isSpanish ? '¡Encuentra los mejores' : 'Find the best'}
                </h2>
                <h3 className="text-white md:text-3xl text-2xl px-10 pt-0">
                  {isSpanish
                    ? `lugares de ${CITY_TO_READABLE[city]}!`
                    : `places in ${CITY_TO_READABLE[city]}!`}
                </h3>
              </div>
              <div className="md:w-[32rem] w-[22rem] pt-[2rem] max-w-lg h-[1rem]">
                <div className="relative w-full">
                  <input
                    type="text"
                    className="border p-2 rounded-md w-full pl-8 z-40 focus:border-[#FC7851] focus:outline-none placeholder:text-[#4B4B4B] placeholder:text-center"
                    placeholder={
                      isSpanish
                        ? 'Busca tu lugar favorito'
                        : 'Search your favorite place'
                    }
                    onFocus={handleSearchFocus}
                  />
                  <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#4B4B4B] pointer-events-none md:translate-x-36 translate-x-[4rem]">
                    <PiMagnifyingGlass />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            {categories.length > 0 && (
              <Suspense
                fallback={
                  <CardsCarouselFallBack title="Descubre Bogotá" small />
                }
              >
                <DiscoverCarousel categories={categories} city={city} />
              </Suspense>
            )}

            {carouselSections.map((section) => (
              <CarouselContainer
                key={section.category}
                {...section}
                city={city}
              />
            ))}
          </div>
        </div>
        <HowToUseSection />
        <SearchModal
          show={showSearchModal}
          onClose={handleCloseSearchModal}
          city={city}
        />
      </div>
    </>
  );
};

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  ctx.res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  );

  const { cityId } = ctx.query;

  if (!SUPPORTED_CITIES.includes(cityId as string)) {
    return {
      notFound: true,
    };
  }

  setCookie(ctx, 'city', cityId as string, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  });

  const city = cityId as string;
  const categories = await getCategories({ city });

  return {
    props: {
      categories: categories?.results || [],
      city,
    },
  };
}

export default Home;
