// CarouselContainer.tsx
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { CardsCarouselFallBack } from './CardsCarouselFallback';

// Simple loading component for dynamic import
const DynamicLoadingFallback = () => null;

const CardsCarousel = dynamic(() => import('./CardsCarousel'), {
  loading: DynamicLoadingFallback,
  ssr: false,
});

interface CarouselContainerProps {
  title: string;
  category: string;
  city: string;
  fetchUrl: string;
}

export const CarouselContainer: React.FC<CarouselContainerProps> = ({
  title,
  category,
  city,
  fetchUrl,
}) => {
  const [spaces, setSpaces] = useState<SpaceType[]>([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isComponentLoading, setIsComponentLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    const fetchSpaces = async () => {
      try {
        const response = await fetch(fetchUrl);
        if (!response.ok) throw new Error('Failed to fetch spaces');
        const data = await response.json();
        if (mounted) {
          setSpaces(data.results || []);
          setCount(data.count);
        }
      } catch (err: any) {
        if (mounted) {
          setError(err.message);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchSpaces();

    return () => {
      mounted = false;
    };
  }, [fetchUrl]);

  // Handle component loading state
  useEffect(() => {
    setIsComponentLoading(true);
    const timer = setTimeout(() => {
      setIsComponentLoading(false);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  if (error) return null;
  if (loading || isComponentLoading)
    return <CardsCarouselFallBack title={title} />;
  if (!spaces.length) return null;

  console.log('category', category, 'spaces.length', spaces.length);

  return (
    <CardsCarousel
      spaces={spaces}
      title={title}
      category={category}
      city={city}
      showMoreButton={count >= 8}
    />
  );
};
